<template lang="pug">
  .matchings-table
    .matchings-table-content
      table.container-fluid
        thead
          tr
            th.item-name.sortable(@click="handleSorting('name')")
              span {{ $t("shop_matching.shop_name") }}
              FaIcon.icon(:icon="sortingIcon('name')")
            th.item-name-dropdown
              span {{ $t("shops_car_class_matching.car_class") }}
            th.select-quantity
        tbody
          ShopItem(
            v-for="(shop, index) in shops"
            :key="shop.id"
            :shop="shop"
            :index="index"
            @select-car-class="$emit('select-car-class', $event)"
          )
</template>

<script>
  // mixins
  import withSorting from "@/mixins/withSorting"
  import withBrowserDetect from "@/mixins/withBrowserDetect"

  export default {
    mixins: [withSorting, withBrowserDetect],

    props: {
      shops: Array,
      sortingData: Object
    },

    components: {
      ShopItem: () => import("./ShopCarClassItem")
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/matchings.sass"

  .matchings-table
    +matchings-table-header

    th
      &.select-quantity
        z-index: 11
        width: 5%
        right: 0
</style>
